import { Component } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'base10';

  public serviceUrl: string;

  public serviceViewerUrl: string;
  public reportPath: string;

  public serverUrl: string;
  
  selectedComponent: string;

  toggleComponent(component: string) {
    this.selectedComponent = component;
  }

    constructor() {
      this.serviceUrl = "https://demos.boldreports.com/services/api/ReportingAPI";
      
      this.serviceViewerUrl = 'https://localhost:44382/api/BoldReports';
      this.reportPath = '~/Resources/noConformidad.rdl';

    }
}
