<!-- 
<div class="container">
    <div class="header"></div>
    <ul class="menu">
      <li (click)="toggleComponent('designer')">Designer</li>
      <li (click)="toggleComponent('viewer')">Viewer</li>
      <li>Viewer</li>
    </ul>
    <div class="content">
      <bold-reportdesigner *ngIf="selectedComponent === 'designer'" id="designer" [serviceUrl] = "serviceUrl" style="position: absolute;height: 850px; width: 1650px;"></bold-reportdesigner>
      <bold-reportviewer *ngIf="selectedComponent === 'viewer'" id="reportViewer_Control" [serviceViewerUrl] = "serviceViewerUrl" [reportPath] = "reportPath" style="width: 100%;height: 950px"></bold-reportviewer> 
     <bold-reportviewer *ngIf="selectedComponent === 'viewer'" id="reportViewer_Control" [reportServiceUrl] = "reportServiceUrl" [processingMode] = "Remote" [reportServerUrl] = "serverUrl" [reportPath]="reportPath">
    </bold-reportviewer>
    </div>
</div> -->

<bold-reportdesigner id="designer" [serviceUrl] = "serviceUrl" style="position: absolute;height: 920px; width: 1890px;"></bold-reportdesigner>

